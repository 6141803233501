import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CLinkCard,
  CDefinition,
  CSliderMedia,
  CFixedImg,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import menuChoice from '../../../utils/menu-choice';
import menuGet from '../../../utils/menu-get';

// markup
const SubPage = ({ data, location }: any) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const { contentId, draftKey } = queryString.parse(location.search);
  const [shopdata, setShopData] = useState<any | null>(shopGet('breakfast'));
  if (contentId) {
    useEffect(() => {
      fetch(
        `https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/shop_srph/${contentId}?draftKey=${draftKey}`,
        {
          headers: {
            'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || '',
          },
        }
      )
        .then((res) => res.json())
        .then((res) => setShopData(res));
    }, []);
  }

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            en: 'breakfast',
            ja: '朝食',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/breakfast/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/breakfast/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
            ],
            current: {
              label: '朝食',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect">
        <section className="u_mbExLarge" id="breakfast">
          <LWrap exClass="u_mb60">
            <CSectTitle title={{ ja: <>朝食</>, en: <>BREAKFAST</> }} />
            <h2 className="c_headingLv2">
              リゾートステイならではの贅沢
              <br />
              気持ちいい環境と美味しい料理 <br />
              “とっておきの朝食体験”
            </h2>
            <p className="c_sectLead">
              美しい景色と心地よい空間で、東北各地のその時季の食材を使った料理を出来たてでたのしむ。
              <br />
              ご宿泊の際は、ここでしか味わえない仙台ロイヤルパークホテル自慢の美味しい朝食をぜひおたのしみください。
              <br />
            </p>
          </LWrap>
          <CFixedImg
            width={800}
            img={{
              src: '/assets/images/restaurants/breakfast/img_breakfastmedia.png',
              alt: '部屋の見取り図',
            }}
          />
          <LWrap size="small">
            <CSliderMedia
              data={[
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/restaurants/breakfast/img_breakfastmedia02.png',
                        alt: '',
                      },
                    },
                  ],
                  title: <>
                  東北・宮城の食材</>,
                  text: (
                    <>
                      歯ごたえと旨みが特徴の宮城県 石巻 十三浜産「わかめ」や竹炭や竹粉などをブレンドした餌で育てられた宮城県 白石「竹鶏たまご」、口の中で心地よくほどける柔らかな食感が特徴の宮城県産「特別栽培ササニシキなど」東北・宮城の豊かな食文化をおたのしみください。
                    </>
                  ),
                  exClass: '__bodyLeft'
                },
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/restaurants/breakfast/img_breakfastmedia03.png',
                        alt: '',
                      },
                    },
                  ],
                  title: <>
                  <span
                    className="u_tal"
                    style={{ display: 'block' }}
                  >
                    こだわりの野菜
                  </span></>,
                  text: (
                    <>
                      福島県
                      郡山「鈴木農場」や宮城県「相原農園」の契約農家などから届くこだわりの野菜で1日のスタートを。
                    </>
                  ),
                  exClass: '__bodyLeft'
                },
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/restaurants/breakfast/img_breakfastmedia04.png',
                        alt: '',
                      },
                    },
                  ],
                  title: (
                    <>
                      仙台ロイヤルパークホテルの
                      <br />
                      朝食名物
                    </>
                  ),
                  text: (
                    <>
                      仙台味噌牛たんカレーや、小松菜やオレンジなど数種類をシェフの絶妙な調合でブレンドされたスムージー、一本まるごとグリルされるアスパラガスは、朝食の人気メニュー。仙台ロイヤルパークホテルにきたら、欠かせないアイテムです。
                    </>
                  ),
                  exClass: '__bodyLeft'
                },
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/restaurants/breakfast/img_breakfastmedia05.png',
                        alt: '',
                      },
                    },
                  ],
                  title: <>シェフの作りたて</>,
                  text: (
                    <>
                      ライブキッチンからシェフが作りたてをお届けするエッグベネディクトやオムレツや、自分好みのアレンジでたのしめるワッフル、焼き立てのパンなど、あつあつをお召しあがりください。
                    </>
                  ),
                  exClass: '__bodyLeft'
                },
              ]}
            />
          </LWrap>
        </section>

        {/* ▼ ニューデザイン　画像も差し替える */}
        {/* <section className="u_mbExLarge" id="breakfast">
          <CSectTitle title={{ ja: <>朝食</>, en: <>BREAKFAST</> }} />
          <h2 className="c_headingLv3 u_tac u_mbLargeMedium">
            心も体もリフレッシュ
            <br />
            五感でたのしむ朝食
          </h2>

          <section className="u_mbMiddleLarge">
            <h3 className="c_headingLv3 u_tac">東北・宮城の食材</h3>
            <div style={{ maxWidth: '1040px' }} className="u_centerring">
              <CFadeSlider
                exClass="u_mbSmall"
                data={[
                  {
                    img: {
                      src: '/assets/images/restaurants/breakfast/img_breakfastmedia.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/breakfast/img_breakfastmedia02.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/breakfast/img_breakfastmedia03.png',
                      alt: '',
                    },
                  },
                ]}
              />
            </div>
            <LWrap>
              <p className="u_tac_pc">
                歯ごたえと旨みが特徴の宮城県 石巻
                十三浜産「わかめ」や竹炭や竹粉などをブレンドした餌で育てられた宮城県
                白石「竹鶏たまご」、
                <br className="u_pc" />
                口の中で心地よくほどける柔らかな食感が特徴の宮城県産「特別栽培ササニシキなど」東北・宮城の豊かな食文化をおたのしみください。
              </p>
            </LWrap>
          </section>

          <section className="u_mbMiddleLarge">
            <h3 className="c_headingLv3 u_tac">こだわりの野菜</h3>

            <div style={{ maxWidth: '1040px' }} className="u_centerring">
              <CFadeSlider
                exClass="u_mbSmall"
                data={[
                  {
                    img: {
                      src: '/assets/images/restaurants/breakfast/img_breakfastmedia04.png',
                      alt: '',
                    },
                  },
                ]}
              />
            </div>
            <LWrap>
              <p className="u_tac_pc">
                福島県
                郡山「鈴木農場」や宮城県「相原農園」の契約農家などからホテルへ直送される野菜は鮮度が違います。
                <br className="u_pc" />
                1日の始まりは新鮮な野菜でスタートを。
              </p>
            </LWrap>
          </section>

          <section className="u_mbMiddleLarge">
            <h3 className="c_headingLv3 u_tac">
              仙台ロイヤルパークホテルの朝食名物
            </h3>
            <div style={{ maxWidth: '1040px' }} className="u_centerring">
              <CFadeSlider
                exClass="u_mbSmall"
                data={[
                  {
                    img: {
                      src: '/assets/images/restaurants/breakfast/img_breakfastmedia05.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/breakfast/img_breakfastmedia06.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/breakfast/img_breakfastmedia07.png',
                      alt: '',
                    },
                  },
                ]}
              />
            </div>
            <LWrap>
              <p className="u_tac_pc">
                牛タン味噌牛たんカレーや、小松菜やオレンジなど数種類をシェフの絶妙な調合でブレンドされたスムージー、
                <br className="u_pc" />
                一本まるごとグリルされるアスパラガスは、朝食の人気メニュー。仙台ロイヤルパークホテルにきたら、欠かせないアイテムです。
              </p>
            </LWrap>
          </section>

          <section className="u_mbMiddleLarge">
            <h3 className="c_headingLv3 u_tac">シェフの作りたて</h3>
            <div style={{ maxWidth: '1040px' }} className="u_centerring">
              <CFadeSlider
                exClass="u_mbSmall"
                data={[
                  {
                    img: {
                      src: '/assets/images/restaurants/breakfast/img_breakfastmedia08.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/breakfast/img_breakfastmedia09.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/breakfast/img_breakfastmedia10.png',
                      alt: '',
                    },
                  },
                ]}
              />
            </div>
            <LWrap>
              <p className="u_tac_pc">
                牛タン味噌牛たんカレーや、小松菜やオレンジなど数種類をシェフの絶妙な調合でブレンドされたスムージー、
                <br className="u_pc" />
                一本まるごとグリルされるアスパラガスは、朝食の人気メニュー。仙台ロイヤルパークホテルにきたら、欠かせないアイテムです。
              </p>
            </LWrap>
          </section>
        </section> */}

        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{ ja: <>営業時間｜ご利用案内</>, en: <>GUIDE</> }}
            />
            <CDefinition
              exClass="u_mb100 u_mb80_sp"
              data={shopdata.information.map((item: any) => {
                return {
                  title:
                    item.keyname &&
                    item.keyname.split('\n').map((t: string) => (
                      <>
                        {t}
                        <br />
                      </>
                    )),
                  text: (
                    <div
                      className="wysiwyg"
                      dangerouslySetInnerHTML={{
                        __html: `${item.value}`,
                      }}
                    />
                  ),
                };
              })}
            />
          </LWrap>
        </section>
      </div>
      <section className="l_sect u_bgGray">
        <LWrap>
          <CSectTitle
            title={{
              ja: <>メニュー</>,
              en: <>MENU</>,
            }}
          />
          <CLinkCard align="center" data={menuChoice(menuGet(), ['朝食'])} />
        </LWrap>
      </section>
      <section className="l_sect" id="picnic">
        <LWrap>
          <CSectTitle
            exClass="u_mb10"
            title={{
              ja: <>イートピクニック</>,
              en: <>EAT PICNIC</>,
            }}
          />
          <CSliderMedia
            data={[
              {
                img: [
                  {
                    img: {
                      src: '/assets/images/restaurants/breakfast/img_picnic.png',
                      alt: 'イートピクニック',
                    },
                  },
                ],
                title: (
                  <>
                    お好きな場所で食事を愉しめる
                    <br />
                    イートピクニック
                  </>
                ),
                text: (
                  <>
                    気持ちいいガーデンをはじめ
                    <br className="u_pc" />
                    お気に入りの場所で朝食をお召し上がりいただける
                    <br className="u_pc" />
                    「イートピクニック」。
                    <br className="u_pc" />
                    ご宿泊のお客様はお気軽にご利用ください。
                  </>
                ),
                btn: {
                  link: {
                    href: '/experience/activity/2yvt3kqzc/',
                  },
                  label: 'イートピクニック',
                },
              },
            ]}
          />
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
